@import '../../Assets/styles/base';
@import '../../Assets/styles/breakpoints';

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function convertToVwSmall($num) {
  $value: strip-unit($num);
  @return ((100 * $value) / 375) * 1vw;
}

.footer {
  position: relative;
  color: #ffffff;
  background: #2a0032 0% 0% no-repeat padding-box;
  border-radius: 20.8vw 20.8vw 0px 0px;
  padding: 10%;
  overflow: hidden;
  // z-index: -2;

  @include desktop {
    padding: 0;
    padding-top: 70px;
    padding-bottom: 59px;
    border-radius: 90px 90px 0 0;
  }

  .footer__container {
    @include desktop {
      padding: 0 5%;
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;

      @include largeDesktop {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .footer__top {
    @include desktop {
      display: flex;
    }
  }

  .logoFormContainer {
    @include desktop {
      flex-basis: 30%;
    }
  }

  .footer__image {
    width: 81%;
    position: absolute;
    z-index: 2;
    right: -36%;
    top: 20%;

    @include desktop {
      max-width: 300px;
      right: 0;
      top: 0;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .siteLogo {
    height: 100%;
    width: 127px;
    width: convertToVwSmall(128px);
    margin-bottom: 4.13vh;
    z-index: 3;

    @include desktop {
      height: auto;
      width: 120px;
      margin-bottom: 0;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  form {
    z-index: 7;
    p {
      font-weight: 400;
      font-family: 'Circular Book';
      color: #39ff14;
      font-size: 12px;
      font-size: convertToVwSmall(12px);
      line-height: 2.5;

      @include desktop {
        font-size: 12px;
        line-height: 1.4;
        margin-bottom: 10px;
      }
    }

    input {
      background: #f1f2f2;
      width: 100%;
      padding: 3% 5%;
      font-size: 12px;
      font-size: convertToVwSmall(12px);
      border: 0;
      border-radius: 7px;
      border-color: #f1f2f2;
      color: #a7a9ac;
      text-align: left;
      font-family: 'Circular Book';

      @include desktop {
        font-size: 12px;
        line-height: 1.4;
        width: 80%;
      }
    }

    button {
      @extend %button-style;
      padding: 3% 5%;
      width: 37%;
      font-size: convertToVwSmall(10px);
      background: #24a90b;
      margin-top: 5%;

      @include desktop {
        font-size: 12px;
        line-height: 1.4;
      }
    }
  }

  nav {
    z-index: 3;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      a {
        text-decoration: none;
        color: #ffffff;
      }

      li {
        font-family: 'Circular Book';
        font-weight: normal;
        font-size: 15px;
        font-size: convertToVwSmall(15px);
        padding-bottom: 8%;

        @include desktop {
          font-size: 15px;
          padding-bottom: 20px;
        }
      }
    }
  }
}

.footer__top__nav {
  margin: 15% 0 10%;

  @include desktop {
    flex-basis: 20%;
    margin: 0;
  }
}

.right__footer__address {
  flex-basis: 25%;
}

.footer__address {
  z-index: 3;
  h5 {
    font-family: 'Circular Book';
    font-weight: normal;
    font-size: 15px;
    font-size: convertToVwSmall(15px);
    margin-bottom: 6%;

    @include desktop {
      font-size: 15px;
    }
  }

  p {
    font-family: 'Circular Book';
    font-weight: normal;
    font-size: 12px;
    font-size: convertToVwSmall(12px);
    margin-bottom: 6%;
    line-height: calc(24 / 16);

    @include desktop {
      font-size: 12px;
    }
  }
}

.horizontalBar {
  z-index: 3;
  border: 2px solid #f2f0f338;
  height: 0;
  width: 100%;

  @include desktop {
    margin: 30px 0;
  }
}

.bottom__nav {
  margin-top: 8%;
  z-index: 3;

  ul {
    display: flex;
    width: 100%;

    li {
      flex-basis: 50%;
      max-width: 50%;
      text-align: left;
      z-index: 3;
    }
  }

  @include desktop {
    margin-top: 0;
    width: 30%;
  }
}

.footer__copyright {
  z-index: 3;
  text-align: center;
  margin-bottom: 10%;
  z-index: 3;

  @include desktop {
    margin-bottom: 0;
  }
}

.footer__bottom {
  @include desktop {
    display: flex;
    justify-content: space-between;
  }
}
